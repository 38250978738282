<template>
  <transition name="fade">
    <v-dialog v-model="showDialog" persistent max-width="400" class="rounded-xl" scrollable>
      <v-card class="pb-5" rounded="lg">
        <v-card-title class="text-h6 d-flex justify-space-between pt-1 pe-1 pb-1 yellow">
          <v-icon color="black">mdi-crown</v-icon>
          <span class="ml-2 black--text">{{ $t("string.myPlan") }}</span>
          <v-spacer></v-spacer>
          <CloseBtn size="30" :callback="callbackClose" />
        </v-card-title>
        <v-img :src="myPlan.imageUrl" class="mb-3" lazy></v-img>
        <v-card-title>{{ myPlan.name }}</v-card-title>
        <!-- <v-card-subtitle>{{ myPlan.description }}</v-card-subtitle> -->
        <v-card-text>
          <v-chip color="success" class="mb-2">{{ $t("string.myPlanStatusActive") }}</v-chip>
          <div>
            {{ $moment(myPlan.timestampStart).format("DD/MM/YYYY") }} ~
            {{ $moment(myPlan.timestampEnd).format("DD/MM/YYYY") }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  props: ["showDialog", "callbackClose", "myPlan"],
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  data: () => ({
    //
  }),
  mounted() {
    console.log(this.myPlan);
  },
};
</script>